import { BIRDI_PLANS } from 'enums/plans';
import { sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { autoRefillEligibleFamilyDataSelector } from 'state/auto-refill/auto-refill.selectors';

import { PrescriptionCardProps, RxDetails } from 'types/prescription';

import { prescriptionPayloadToProps } from 'util/payload-to-props';
import { doesPlanAllowsPricing } from 'util/pricing';

import usePrescriptionFlow from 'hooks/usePrescriptionFlow';
import usePrescriptionPrice from 'hooks/usePrescriptionPrice';

export interface PriceHistory {
    planAlias: string;
    zipCode: string;
    drugCode: string;
}

const useAutoRefillPrescriptions = () => {
    const { t } = useTranslation();
    const { familyPlanList, ownerPlan } = usePrescriptionFlow();
    const [activeTab, setActiveTab] = useState<string | null>(null);
    const autoRefillFamilyDataFiltered = useSelector(autoRefillEligibleFamilyDataSelector);

    // Get the planAlias of the selected user in the medicine cabinet
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedPlan = useMemo(
        () => (activeTab && familyPlanList[activeTab] ? familyPlanList[activeTab] : ownerPlan) as BIRDI_PLANS,
        [ownerPlan, activeTab, familyPlanList]
    );

    const { showInsurancePrice, pricingZipCode, getPrescriptionPrices, mapPrescriptionsWithPrice } =
        usePrescriptionPrice();

    // Validate if the user's plan should display prices
    const shouldPlanShowPrice: boolean = useMemo(
        () => showInsurancePrice || doesPlanAllowsPricing(selectedPlan),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showInsurancePrice, selectedPlan]
    );

    // Gather specific user data
    const selectedPatientData = useMemo(
        () => autoRefillFamilyDataFiltered.find((patient) => patient.epostPatientNum === activeTab) || null,
        [activeTab, autoRefillFamilyDataFiltered]
    );

    // We process once the prescription card data transforming
    // it to the correspondent object to render prescription cards
    const prescriptionCards = useMemo(() => {
        if (!selectedPatientData || selectedPatientData.patientAutoRefill === false) return [];

        // TODO: There is no a way to know if the plan is onDemand at the dependent
        // level being returned in the Easy refill APIs. Hardcoding for now.
        const isOnDemandPlan = false;

        return selectedPatientData.rxResults.length > 0
            ? selectedPatientData.rxResults.map((prescription) =>
                  prescriptionPayloadToProps(prescription, t, selectedPatientData.planAlias, isOnDemandPlan)
              )
            : [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPatientData]);

    // When all the information has been loaded, validate which Rxs are eligible to
    // get pricing from api
    const priceEligiblePrescriptions: RxDetails[] = useMemo(() => {
        if (!!pricingZipCode && prescriptionCards.length > 0 && shouldPlanShowPrice) {
            return prescriptionCards.filter((rx) => rx.showPrice).map((rx) => rx.fullRxItem);
        } else {
            return [];
        }
    }, [pricingZipCode, prescriptionCards, shouldPlanShowPrice]);

    // Adding the price to the prescriptions
    const prescriptionCardsWithprice: PrescriptionCardProps[] = useMemo(
        () => mapPrescriptionsWithPrice(prescriptionCards, shouldPlanShowPrice, selectedPlan),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [prescriptionCards, mapPrescriptionsWithPrice]
    );

    // We process once the prescription card data transforming
    // it to the correspondent object to render prescription cards
    const sortedPrescriptions = useMemo(
        () => sortBy(prescriptionCardsWithprice, ['sortOrder', 'prescriptionName']),
        [prescriptionCardsWithprice]
    );

    // Trigger event to get prices from rx once information has been loaded
    useEffect(() => {
        if (priceEligiblePrescriptions.length > 0) {
            getPrescriptionPrices(priceEligiblePrescriptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceEligiblePrescriptions]);
    return { activeTab, setActiveTab, sortedPrescriptions, selectedPatientData };
};

export default useAutoRefillPrescriptions;
